<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="window px-5 pt-4 pb-16">
          <!--#region Type Buttons -->
          <v-row class="mb-1 mt-0">
            <div v-if="!isSubagent">
              <v-btn
                v-for="type of types"
                :key="type.key"
                tile
                class="mx-0"
                elevation="0"
                :dark="selectedType.key == type.key"
                :color="selectedType.key == type.key ? 'orange' : ''"
                @click="selectedType = type"
              >
                {{ type.label }}
              </v-btn>
            </div>
          </v-row>
          <!--#endregion-->
          <!--#region Title -->
          <v-row v-if="isSubagent" justify="center" class="mb-2">
            <h1>Найти Рейс</h1>
          </v-row>
          <v-row v-else justify="center" class="mb-2">
            <h1 v-if="selectedType.key == 'avia'">Найти Рейс (Авиа)</h1>
            <h1 v-if="selectedType.key == 'rzd'">Найти Рейс (ЖД)</h1>
            <h1 v-if="selectedType.key == 'hotel'">Найти Отель</h1>
          </v-row>
          <!--#endregion-->

          <!--#region Form -->
          <div v-if="selectedType.key == 'avia' || selectedType.key == 'rzd'">
            <v-row justify="center" class="mb-1 mt-10">
              <v-btn
                class="mx-1"
                elevation="0"
                :dark="oneway"
                :color="oneway ? 'orange' : ''"
                @click="
                  () => {
                    date.To = '';
                    oneway = true;
                  }
                "
              >
                в одну сторону
              </v-btn>
              <v-btn
                class="mx-1"
                elevation="0"
                :dark="!oneway"
                :color="!oneway ? 'orange' : ''"
                @click="
                  () => {
                    date.To = date.From;
                    oneway = false;
                  }
                "
              >
                туда и обратно
              </v-btn>
            </v-row>
            <v-row justify="center">
              <v-col>
                <v-form
                  v-model="valid"
                  ref="form"
                  :action="selectedType.url"
                  @submit="submit"
                >
                  <v-row>
                    <v-col cols="12" md="5">
                      <input-town
                        v-model="depCity"
                        label="Откуда"
                        :start="startCity"
                        :endpoint="selectedType.travelPointUrl"
                        :is-railway="selectedType.key == 'rzd'"
                      ></input-town>
                    </v-col>
                    <v-spacer />
                    <v-col cols="1" class="text-center" align-self="center">
                      <v-btn
                        block
                        color="orange"
                        @click="swapCities"
                        tabindex="-1"
                      >
                        <v-icon large>mdi-swap-horizontal</v-icon>
                      </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" md="5">
                      <input-town
                        v-model="arrCity"
                        label="Куда"
                        start=""
                        :endpoint="selectedType.travelPointUrl"
                        :is-railway="selectedType.key == 'rzd'"
                      ></input-town>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6">
                      <custom-date-picker
                        v-model="date.From"
                        :min="date.Today"
                        no-title
                        format="dd.MM.yyyy"
                      >
                      </custom-date-picker>
                    </v-col>
                    <v-col>
                      <custom-date-picker
                        v-model="date.To"
                        no-title
                        :min="date.from"
                        :disabled="oneway"
                        format="dd.MM.yyyy"
                      >
                      </custom-date-picker>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" :md="selectedType.key == 'avia' ? 3 : 4">
                      <v-text-field
                        type="number"
                        min="0"
                        label="Взрослые"
                        v-model="adults"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" :md="selectedType.key == 'avia' ? 3 : 4">
                      <v-text-field
                        type="number"
                        min="0"
                        label="Дети"
                        v-model="kids"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4" :md="selectedType.key == 'avia' ? 3 : 4">
                      <v-text-field
                        type="number"
                        min="0"
                        label="Младенцы"
                        v-model="babe"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="selectedType.key == 'avia'" cols="12" md="3">
                      <v-select
                        label="Класс"
                        :items="classes"
                        v-model="fClass"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row class="mt-4 mx-0" justify="end">
                    <v-btn
                      :disabled="isSearchDisabled"
                      block
                      dark
                      type="submit"
                      elevation="0"
                      color="orange"
                    >
                      Найти Рейс
                    </v-btn>
                  </v-row>
                  <div v-if="tickets">
                    <div v-for="ticket in tickets" :key="ticket.id">
                      <h2>{{ ticket.trainNumber }}</h2>
                      <p>Departure: {{ ticket.departureDateTime }}</p>
                      <p>Arrival: {{ ticket.arrivalDateTime }}</p>
                      <p>Duration: {{ ticket.tripDuration }}</p>
                      <div
                        v-for="carGroup in ticket.carGroups"
                        :key="carGroup.carType"
                      >
                        <h3>{{ carGroup.carType }}</h3>
                        <p>Min Ticket Price: {{ carGroup.minTicketPrice }}</p>
                        <p>Max Ticket Price: {{ carGroup.maxTicketPrice }}</p>
                      </div>
                    </div>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </div>
          <div v-if="selectedType.key == 'hotel'" class="mt-10">
            <v-row justify="center">
              <v-col>
                <v-form ref="form" @submit="submit">
                  <v-row>
                    <v-col cols="12" md="3">
                      <input-town
                        v-model="hotelForm.city"
                        label="Город"
                        :start="startCity"
                        :endpoint="selectedType.travelPointUrl"
                      ></input-town>
                    </v-col>
                    <v-col cols="12" md="3">
                      <custom-date-picker
                        v-model="hotelForm.date.from"
                        :min="date.Today"
                        no-title
                        text="Дата заселения"
                        format="dd.MM.yyyy"
                      >
                      </custom-date-picker>
                    </v-col>
                    <v-col cols="12" md="3">
                      <custom-date-picker
                        v-model="hotelForm.date.to"
                        no-title
                        text="Дата выселения"
                        :min="hotelForm.date.from"
                        format="dd.MM.yyyy"
                      >
                      </custom-date-picker>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="hotelForm.clientsCount"
                        type="number"
                        min="1"
                        label="Гости"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-10 mx-0" justify="end">
                    <v-btn
                      :disabled="!isHotelValid"
                      block
                      dark
                      type="submit"
                      elevation="0"
                      color="orange"
                    >
                      Найти Отель
                    </v-btn>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </div>
          <!--#endregion-->
        </v-card>
      </v-col>
    </v-row>

    <pre-offer-flights v-if="!isSubagent" v-show="selectedType.key == 'avia'" />
  </v-container>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import PreOfferFlights from "@/components/FindTktPage/PreOfferFlights.vue";
import DateFormat from "@/mixins/DateFormat";
import { format } from "date-fns";
import InputTown from "../components/UI/InputTown.vue";

export default {
  name: "FindTkt",
  mixins: [DateFormat],
  components: {
    InputTown,
    CustomDatePicker,
    PreOfferFlights,
  },
  data() {
    return {
      date: {
        From: new Date(),
        To: new Date(),
        Today: new Date(),
      },
      valid: false,
      oneway: true,
      adults: 1,
      kids: 0,
      babe: 0,
      fClass: 0,
      classes: [
        { text: "Эконом", value: 0 },
        { text: "Бизнес", value: 1 },
      ],
      //-----------------------------------------//
      arrCity: {
        text: "",
        value: {
          code: "",
          name: "",
        },
      },
      depCity: {
        text: "",
        value: {
          code: "",
          name: "",
        },
      },
      types: [
        {
          key: "avia",
          label: "авиа",
          url: "/found-tickets",
          travelPointUrl: this.$enums.Endpoints.Data.Stations,
        },
        {
          key: "rzd",
          label: "жд",
          url: "trs-list",
          travelPointUrl: this.$enums.Endpoints.RailwayData.RailwayStantions,
        },
        {
          key: "hotel",
          label: "отели",
          url: "/find-tkt",
          travelPointUrl: this.$enums.Endpoints.Data.Stations,
        },
      ],
      selectedType: { key: "avia", label: "авиа", url: "/found-tickets" },
      //-----------------------------------------//
      // Далее - для ЖД, вынести в компонент
      railwayCities: [],
      timerFilter: null,
      tickets: null,
      isLoading: false,
      //------------- hotel --------------------//
      hotelForm: {
        city: {
          text: "",
          value: {
            code: "",
            name: "",
          },
        },
        date: {
          from: new Date(),
          to: new Date(),
        },
        clientsCount: 1,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.depCity = {
        text: "",
        value: {
          code: "",
          name: "",
        },
      };
      vm.arrCity = {
        text: "",
        value: {
          code: "",
          name: "",
        },
      };
    });
  },
  mounted() {
    this.$store.dispatch("clearTrainSegments");
  },

  computed: {
    isSearchDisabled() {
      const isCitiesEmpty =
        !(this.depCity && this.depCity.text) ||
        !(this.arrCity && this.arrCity.text);
      const isCitiesSame =
        this.depCity && this.arrCity && this.depCity.text === this.arrCity.text;
      return (
        isCitiesEmpty ||
        isCitiesSame ||
        (!this.oneway && this.date.From > this.date.To)
      );
    },
    isHotelValid() {
      return !!this.hotelForm.city.text && this.hotelForm.clientsCount > 0;
    },
    startCity() {
      let dict = new Map([
        [1707, "LED"], // Пулково
        [2009, "MOW"], // Шереметьево
        [2010, "MOW"], // Домодедово
      ]);
      let code = dict.get(this.$store.getters.user.airportId);
      return code ?? "";
    },
    isSubagent() {
      return this.$store.getters.user.role === this.$enums.Roles.SubAgent;
    },
  },

  watch: {
    hotelForm: {
      handler(newValue) {
        console.info(this.hotelForm);
        console.info(this.isHotelValid);
      },
      deep: true,
    },
    selectedType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.depCity = {
          text: "",
          value: {
            code: "",
            name: "",
          },
        };
        this.arrCity = {
          text: "",
          value: {
            code: "",
            name: "",
          },
        };
      }
    },
  },

  methods: {
    swapCities() {
      let temp = this.depCity;
      this.depCity = this.arrCity;
      this.arrCity = temp;
    },

    submit(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        const searchData = {
          from: this.depCity.text,
          to: this.arrCity.text,
          date: format(this.date.From, "yyyy-MM-dd"),
          adult: this.adults,
          child: this.kids,
          infant: this.babe,
        };

        if (this.selectedType.key == "avia") {
          this.$router.push(
            `/found-tickets?src=${this.depCity.value.code}&dest=${
              this.arrCity.value.code
            }&date=${searchData.date}&back=${
              this.oneway ? "" : format(this.date.To, "yyyy-MM-dd") ?? ""
            }&cls=${this.fClass}&adults=${searchData.adult}&kids=${
              searchData.child
            }&bbs=${searchData.infant}`
          );
        }

        if (this.selectedType.key == "rzd") {
          let url = `/trs-list?src=${this.depCity.text}&dest=${this.arrCity.text}&codeSrc=${this.depCity.value.code}&codeDest=${this.arrCity.value.code}&date=${searchData.date}&adults=${this.adults}&kids=${this.kids}&bbs=${this.babe}&oneway=${this.oneway}`;
          if (!this.oneway)
            url += `&dateFrom=${format(this.date.To, "yyyy.MM.dd")}`;
          this.$router.push(url);
        }
      }
    },
  },
};
</script>

<style scoped></style>
