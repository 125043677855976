<template>
  <v-row v-if="!!offers?.length">
    <v-col cols="12">
      <h3 class="mb-4">Специальные предложения</h3>
    </v-col>

    <v-col
      v-for="offer in offers"
      :key="offer.templateId"
      lg="auto"
      md="auto"
      sm="6"
      cols="12"
    >
      <v-card class="pa-3 pt-2">
        <v-card-title class="pa-0 mb-3 text-subtitle-1"
          >{{ offer.from.nameRu }} — {{ offer.to.nameRu }}</v-card-title
        >

        <div class="prices">
          <router-link
            v-for="price in offer.prices"
            :key="price.date"
            :to="linkToOffers(offer.from, offer.to, price.date)"
            class="price-item"
          >
            <v-card class="py-1 px-3" outlined>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 16px;
                "
              >
                <span class="caption mb-0 blue-grey--text text--lighten-4">
                  {{ price.dateFormatted }}
                </span>

                <div>
                  <v-img :src="price.carrier.imgUrl" width="16" />
                </div>
              </div>
              <p class="body-2 mb-0">
                <span style="color: greenyellow"> от: </span>
                <span class="font-weight-bold"> {{ price.minPrice }} ₽ </span>
              </p>
            </v-card>
          </router-link>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "PreOfferFlights",

  data() {
    return {
      offers: [],
    };
  },

  async mounted() {
    this.offers = await this.getOffers();
  },

  methods: {
    async getOffers() {
      const offers = (
        await this.$axios.get(this.$enums.Endpoints.Other.PreOffers.GetList)
      ).data;

      const today = moment();

      const getFormattedDate = (priceDate) => {
        const date = moment(priceDate, "DD.MM.YYYY");

        if (date.isBefore(today, "days")) {
          return "Вчера";
        }

        if (date.isSame(today, "days")) {
          return "Сегодня";
        }

        date.add(-1, "days");

        if (date.isSame(today, "days")) {
          return "Завтра";
        }

        return moment(priceDate, "DD.MM.YYYY").format("DD.MM");
      };

      return offers
        ?.filter(({ prices }) => !!prices?.length)
        .map((offer) => ({
          ...offer,
          prices: offer.prices
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((price) => {
              return {
                ...price,
                dateFormatted: getFormattedDate(price.date),
              };
            }),
        }));
    },

    linkToOffers(from, to, date) {
      return `/found-tickets?src=${from.iata}&dest=${to.iata}&date=${moment(
        date,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD")}&back=&cls=0&adults=1&kids=0&bbs=0`;
    },
  },
};
</script>

<style scoped>
.offers {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.prices {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.price-item {
  text-decoration: none;
}
</style>
